define("discourse/plugins/chat/discourse/components/chat-footer", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/service", "ember-modifier", "discourse/components/d-button", "discourse/helpers/concat-class", "discourse/lib/ajax-error", "discourse-common/helpers/i18n", "truth-helpers/helpers/eq", "@ember/template-factory", "@ember/component"], function (_exports, _component, _tracking, _service, _emberModifier, _dButton, _concatClass, _ajaxError, _i18n, _eq, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatFooter = _exports.default = (_class = (_class2 = class ChatFooter extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "chat", _descriptor2, this);
      _initializerDefineProperty(this, "chatApi", _descriptor3, this);
      _initializerDefineProperty(this, "threadsEnabled", _descriptor4, this);
      _defineProperty(this, "updateThreadCount", (0, _emberModifier.modifier)(() => {
        const ajax1 = this.chatApi.userThreadCount();
        ajax1.then(result1 => {
          this.threadsEnabled = result1.thread_count > 0;
        }).catch(error1 => {
          (0, _ajaxError.popupAjaxError)(error1);
        });
        return () => {
          ajax1?.abort();
        };
      }));
    }
    get directMessagesEnabled() {
      return this.chat.userCanAccessDirectMessages;
    }
    get shouldRenderFooter() {
      return this.directMessagesEnabled || this.threadsEnabled;
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if this.shouldRenderFooter}}
        <nav class="c-footer" {{this.updateThreadCount}}>
          <DButton
            @route="chat.channels"
            @class={{concatClass
              "btn-flat"
              "c-footer__item"
              (if (eq this.router.currentRouteName "chat.channels") "--active")
            }}
            @icon="comments"
            @id="c-footer-channels"
            @translatedLabel={{i18n "chat.channel_list.title"}}
            aria-label={{i18n "chat.channel_list.aria_label"}}
          />
  
          {{#if this.directMessagesEnabled}}
            <DButton
              @route="chat.direct-messages"
              @class={{concatClass
                "btn-flat"
                "c-footer__item"
                (if
                  (eq this.router.currentRouteName "chat.direct-messages")
                  "--active"
                )
              }}
              @icon="users"
              @id="c-footer-direct-messages"
              @translatedLabel={{i18n "chat.direct_messages.title"}}
              aria-label={{i18n "chat.direct_messages.aria_label"}}
            />
          {{/if}}
  
          {{#if this.threadsEnabled}}
            <DButton
              @route="chat.threads"
              @class={{concatClass
                "btn-flat"
                "c-footer__item"
                (if (eq this.router.currentRouteName "chat.threads") "--active")
              }}
              @icon="discourse-threads"
              @id="c-footer-threads"
              @translatedLabel={{i18n "chat.my_threads.title"}}
              aria-label={{i18n "chat.my_threads.aria_label"}}
            />
          {{/if}}
        </nav>
      {{/if}}
    
  */
  {
    "id": "s1KbSQir",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldRenderFooter\"]],[[[1,\"      \"],[11,\"nav\"],[24,0,\"c-footer\"],[4,[30,0,[\"updateThreadCount\"]],null,null],[12],[1,\"\\n        \"],[8,[32,0],[[16,\"aria-label\",[28,[32,1],[\"chat.channel_list.aria_label\"],null]]],[[\"@route\",\"@class\",\"@icon\",\"@id\",\"@translatedLabel\"],[\"chat.channels\",[28,[32,2],[\"btn-flat\",\"c-footer__item\",[52,[28,[32,3],[[30,0,[\"router\",\"currentRouteName\"]],\"chat.channels\"],null],\"--active\"]],null],\"comments\",\"c-footer-channels\",[28,[32,1],[\"chat.channel_list.title\"],null]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"directMessagesEnabled\"]],[[[1,\"          \"],[8,[32,0],[[16,\"aria-label\",[28,[32,1],[\"chat.direct_messages.aria_label\"],null]]],[[\"@route\",\"@class\",\"@icon\",\"@id\",\"@translatedLabel\"],[\"chat.direct-messages\",[28,[32,2],[\"btn-flat\",\"c-footer__item\",[52,[28,[32,3],[[30,0,[\"router\",\"currentRouteName\"]],\"chat.direct-messages\"],null],\"--active\"]],null],\"users\",\"c-footer-direct-messages\",[28,[32,1],[\"chat.direct_messages.title\"],null]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"threadsEnabled\"]],[[[1,\"          \"],[8,[32,0],[[16,\"aria-label\",[28,[32,1],[\"chat.my_threads.aria_label\"],null]]],[[\"@route\",\"@class\",\"@icon\",\"@id\",\"@translatedLabel\"],[\"chat.threads\",[28,[32,2],[\"btn-flat\",\"c-footer__item\",[52,[28,[32,3],[[30,0,[\"router\",\"currentRouteName\"]],\"chat.threads\"],null],\"--active\"]],null],\"discourse-threads\",\"c-footer-threads\",[28,[32,1],[\"chat.my_threads.title\"],null]]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-footer.js",
    "scope": () => [_dButton.default, _i18n.default, _concatClass.default, _eq.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "chatApi", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "threadsEnabled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class);
});